import { fonts } from '@lumoslabs/lumosity-storybook'
import styled from 'styled-components'

import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import Chevron from '~/images/chevron_right.svg'

const { Body1, MicroText2SemiboldCaps } = fonts

export const GameCardAura = styled.div`
  padding: 8px;
  position: relative;
`

export const AreaLabel = styled(MicroText2SemiboldCaps)`
  color: ${(props) => props.theme.colors.coolGray44};
`

export const CardShadowRadius = styled.div`
  background: ${(props) => props.theme.colors.whiteBase};
  box-shadow: ${(props) => props.theme.card.shadow};
  border-radius: 16px;
`

export const ExitButtonContainer = styled.div`
  padding-left: 40px;
  > a {
    margin-top: 128px;
    min-height: 0;
    min-width: 0;
    box-sizing: content-box;
  }
  ${({ theme }) => theme.mediaQuery.maxWidth.tablet} {
    a {
      > span:first-child {
        padding: 0;
        > svg {
          padding: 0;
          text-align: center;
        }
      }
      > span:nth-child(2) {
        display: none;
      }
    }
  }
`

export const UnlockBadge = styled.div`
  width: 64px;
`

export const BackButtonBadge = styled(Chevron)`
  font-size: 19px;
  transform: rotate(180deg);
  padding-left: 20px;
`

export const Error = () => {
  const t = useTranslationForNamespace('common')
  return (
    <Body1 as='h1'>
      {/* Include fallback text error about being offline in English as translations don't work offline */}
      {t(
        'errorMessages.miscReloadError',
        {},
        {
          fallback: "Looks like you're offline! Please check your internet connection and try again.",
        },
      )}
    </Body1>
  )
}
